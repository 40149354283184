import React from 'react';
import '../styles/Skills.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faDatabase, faLaptopCode,faImage,faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faReact, faAngular, faHtml5, faCss3, faNode,faMicrosoft,faBitcoin,faEthereum } from '@fortawesome/free-brands-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Skills() {
    const skills = [
        {
          category: 'Frontend Development',
          items: [
            { name: 'React', icon: <FontAwesomeIcon icon={faReact} /> },
            { name: 'AngularJS', icon: <FontAwesomeIcon icon={faAngular} /> },
            { name: 'HTML', icon: <FontAwesomeIcon icon={faHtml5} /> },
            { name: 'CSS', icon: <FontAwesomeIcon icon={faCss3} /> },
          ],
        },
        {
          category: 'Backend Development',
          items: [
            { name: 'C#', icon: <FontAwesomeIcon icon={faCode} /> },
            { name: '.NET Core', icon: <FontAwesomeIcon icon={faMicrosoft} /> },
            { name: 'Node.js', icon: <FontAwesomeIcon icon={faNode} /> },
            { name: 'SQL', icon: <FontAwesomeIcon icon={faDatabase} /> },
          ],
        },
        {
          category: 'Blockchain Development',
          items: [
            { name: 'Bitcoin Blockchain', icon: <FontAwesomeIcon icon={faBitcoin} /> },
            { name: 'Ethereum Blockchain', icon: <FontAwesomeIcon icon={faEthereum} /> },
            { name: 'Opensea/NFT Development', icon: <FontAwesomeIcon icon={faImage} /> },
            { name: 'Analytical Blockchain', icon: <FontAwesomeIcon icon={faChartLine} /> },
          ],
        },
      ];
    
      return (
        <section className="skills" id="skills">
          <div className="skills-content">
            <h2 className="skills-title">Skills & Expertise</h2>
            <p className="skills-intro">
            I specialize in building scalable and efficient web applications tailored to meet business needs. With expertise in modern technologies like C#, React, and .NET, I deliver high-quality solutions that enhance productivity, streamline workflows, and create user-friendly experiences.
            </p>
            <div className="skills-grid">
              {skills.map((category, index) => (
                <div key={index} className="skills-category">
                  <h3 className="category-title">{category.category}</h3>
                  <div className="skills-icons">
                    {category.items.map((skill, idx) => (
                      <div key={idx} className="skill-item">
                        <div className="skill-icon">{skill.icon}</div>
                        <p className="skill-name">{skill.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      );
  }

export default Skills;
