import {React, useEffect} from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import { useSearchParams } from 'react-router-dom';
import ProjectContent from '../components/ProjectContent';

function ProjectDetail() {
  const [searchParams] = useSearchParams();

  const myParam = searchParams.get('project');

  return (
    <div>
        <ProjectContent projectName={myParam}></ProjectContent>
    </div>
  );
}

export default ProjectDetail;
