import React from 'react';
import '../styles/Contact.css';

function Contact() {
  return (
    <div className="contact-page" id='contact'>
      {/* Pricing Section */}
      <section className="pricing-section">
        <h2 className="section-title">Pricing</h2>
        <p className="section-intro">
          Below are my package rates for fixed deliverables and day rates for custom or ongoing work. Get in touch for a personalized quote!
        </p>

        {/* Fixed Packages */}
        <div className="pricing-cards">
          <div className="pricing-card">
            <h3>Basic Website Package</h3>
            <p className="price">£650</p>
            <ul>
              <li>Single-page website</li>
              <li>Responsive design</li>
              <li>Basic HTML/CSS styling</li>
              <li>Up to 2 revisions</li>
            </ul>
          </div>
          <div className="pricing-card">
            <h3>Standard Website Package</h3>
            <p className="price">£1,500</p>
            <ul>
              <li>Up to 5 pages</li>
              <li>Frontend (React/AngularJS) & Backend (.NET Core/Node.js)</li>
              <li>SEO optimization</li>
              <li>Database integration</li>
              <li>Up to 5 revisions</li>
            </ul>
          </div>
          <div className="pricing-card">
            <h3>Advanced Blockchain Package</h3>
            <p className="price">Starting at £4,000</p>
            <ul>
              <li>Blockchain development (Bitcoin/Ethereum)</li>
              <li>Smart contract creation</li>
              <li>NFT marketplace integration</li>
              <li>Performance tracking tools</li>
              <li>Unlimited revisions (within scope)</li>
            </ul>
          </div>
        </div>

        {/* Day Rates */}
        <div className="day-rates">
          <h3>Day Rates for Custom Work</h3>
          <p className="day-rate-intro">
            For open-ended or undefined projects, I offer day rates based on the type of work:
          </p>
          <ul className="day-rate-list">
            <li>
              <strong>Frontend/Backend Development:</strong> £400/day
            </li>
            <li>
              <strong>Blockchain Development:</strong> £750/day
            </li>
          </ul>
          <p>
            Contact me for more details or to get a tailored quote for your project.
          </p>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="contact-section">
        <h2 className="section-title">Get in Touch</h2>
        <p className="section-intro">
          Have a project in mind? Fill out the form below, and I'll get back to you within 48 hours.
        </p>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" placeholder="Enter your name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Enter your email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              rows="5"
              placeholder="Enter your message"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn-primary">
            Send Message
          </button>
        </form>
      </section>
    </div>
  );
}

export default Contact;
