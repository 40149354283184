import React from 'react';
import '../styles/ProjectContent.css';

function ProjectContent({ projectName }) {
  const projectData = {
    houseOfFirst: {
      title: "House Of First",
      description: "Bringing NFT collections to life, blending art, innovation, and exclusive experiences to connect artists and collectors in the Web3 era",
      details: "House of First is a platform that showcases exclusive NFT collections from exceptional artists, offering licensed digital artwork, commercial rights, and curated collections. It engages users through utilities like real-world events and community-driven experiences while combining blockchain technology with creative storytelling. My role involved web design, user experience, and integrating blockchain features to create a seamless, engaging digital marketplace that connects artists and collectors",
      promoImage: "/images/houseoffirstcollections.PNG",
      logo:"/images/houseoffirst.png",
      website:"https://houseoffirst.com/",
      products: [
        {
          name: "Collections",
          image: "/images/collectionsBackground.jpg",
          languages: "C# Web API, Console Scripts, Azure Containers, SQL, IIS, React, Ethereum Blockchain",
          description: "We collaborated with renowned artists to bring their artwork to the blockchain, preserving their creations forever while providing them with the exposure they truly deserve. Using a variety of tools and technologies, we developed six unique art collections, combining creativity and innovation to showcase their work in the digital world",
          pageUrl:"https://houseoffirst.com/collections/blackisbeautiful"
        },
        {
          name: "Staking",
          image: "/images/stakingImage.gif",
          languages: "React, Node.js, Ethereum Blockchain, Mongo",
          description: "We developed a product that enables NFT holders to earn rewards, which can be redeemed for exclusive prizes. By leveraging blockchain technology, we tracked and referenced rewards, ensuring transparency and security throughout the process",
          pageUrl:"https://houseoffirst.com/staking"
        },
        {
          name: "Marketplace",
          image: "/images/houseoffirstmarketplace.jpeg",
          languages: "C# Web API, React, Ethereum Blockchain, SQL",
          description: "After staking their NFTs, users could redeem their rewards in a dynamic marketplace that periodically updates with new and exciting offerings.",
           pageUrl:"https://houseoffirst.com/marketplace"
        },
        {
          name: "Wallet",
          image: "/images/wallet.png",
          languages: "C# Web API, SQL, React, Azure, Ethereum Blockchain",
          description: "Holders can access their creations directly in their personal wallets and even download the full high-resolution image for their collection.",
           pageUrl:"https://houseoffirst.com/wallet"
        },

      ],
    },
    betterBlocks: {
      title: "Better Blocks",
      description: "Better Blocks, a new Web3 platform that provides a suite of innovative products and tools to scale NFT projects",
      details: "Better Blocks is a user-friendly Web3 development platform designed to empower founders and creators by providing access to a suite of cutting-edge Web3 tools and resources. Its mission is to simplify blockchain development, enabling users to build and scale their projects without requiring extensive technical expertise",
      promoImage: "/images/betterblockspromo4.PNG",
      logo:"/images/betterblocks.png",
      theme:'#022238',
      textTheme:'#00e3b4',
      website:"https://www.betterblocks.io/",
      products: [
        {
          name: "Spotlight Bot",
          image: "/images/spotlightbot.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Imagine if your NFT collection could promote itself! You can create tweets that highlight the rarest features of your NFTs, set up automated posts to share random items from your collection daily or weekly, and easily draw more attention to what makes your NFTs special",
          pageUrl:"https://app.betterblocks.io/blocks/spotlightbot"
        },
        {
          name: "Flex Bot",
          image: "/images/flexbot.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Want to show off your NFTs like a pro? Craft the perfect tweets to highlight their rarest features, set up automated posts to flex your collection daily or weekly, and easily grab more attention on social media for your prized assets!",
          pageUrl:"https://app.betterblocks.io/blocks/flexbot"
        },
        {
          name: "Sales Bot",
          image: "/images/salesbot.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Stay on top of your NFT collection's performance with real-time sales monitoring. Automatically share updates on social media for effortless promotion and increased visibility of your collection!",
           pageUrl:"https://app.betterblocks.io/blocks/salesbot"
        },
        {
          name: "Rewards Bot",
          image: "/images/rewardsbot.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Looking to engage and reward your Twitter community effortlessly? With seamless Twitter integration, you can set up automated systems to reward your followers, making it easy to show appreciation and keep your community active and engaged—no extra effort required!",
           pageUrl:"https://app.betterblocks.io/blocks/rewardsbot"
        },
        {
          name: "Snapshot Tool",
          image: "/images/snapshottool.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Need to keep track of your NFT collection over time? Create snapshots of your collection at any moment, download the data in various formats, and access your saved history to easily view and compare previous snapshots whenever you need.",
           pageUrl:"https://app.betterblocks.io/blocks/snapshot"
        },
      ],
    },
    rsop: {
      title: "Royal Society Of Players",
      description: "Royal Society of Players, an exclusive membership-based global community delivering the highest levels of utility, rewards, and experiences",
      details: "Unlock the full potential of your project with a community-driven experience like the Royal Society of Players (RSOP). Imagine creating a bespoke NFT ecosystem, like RSOP’s handcrafted playing cards and companion collections, that not only offers stunning designs but also drives engagement with staking rewards, tokens, and real-world perks. With my expertise in Web3 development, I can help you build a thriving NFT platform that bridges the gap between the metaverse and reality, fostering a dedicated community through gamification, rewards, and exclusive events",
      promoImage: "/images/rsoppromo.png",
      logo:"/images/rsop.png",
      backgroundTheme:"/images/chipsbg.png",
      textTheme:'#00e3b4',
      website:"https://www.royalsociety.io/",
      products: [
        {
          name: "Collections",
          image: "/images/rsopcards.gif",
          languages: "C# Web API, React, MSSQL, Ethereum Blockchain, Opensea API, Azure Containers, IIS",
          description: "The Royal Society of Players (RSOP) collections serve as the backbone of a thriving NFT-powered ecosystem, providing both utility and exclusivity for members",
          pageUrl:"https://www.royalsociety.io/#nfts"
        },
        {
          name: "Staking",
          image: "/images/rsopstaking.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain",
          description: "Players can stake their cards to earn ROYAL, an exclusive in-house points system, which can later be redeemed in the RSOP Marketplace. Rewards are determined by the strength of their hand, calculated according to official poker rules, adding a strategic and competitive layer to the staking experience",
          pageUrl:"https://www.royalsociety.io/staking"
        },
        {
          name: "Players Lounge",
          image: "/images/rsopplayerslounge.gif",
          languages: "C# Web API, React, MSSQL, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "Players can immerse themselves in a vibrant virtual lounge, where they can spend the ROYAL they've earned in the marketplace, customize their NFTs through the innovative Butcher’s Block, and even share the experience by gifting ROYAL to friends. This interactive space fosters creativity, personalization, and community engagement, making every player's journey uniquely their own",
           pageUrl:"https://www.royalsociety.io/royal"
        },
        {
          name: "Marketplace (Bidding System)",
          image: "/images/rsopmarketplace.png",
          languages: "C# Web API, React, Mongo, Ethereum Blockchain, Opensea API, Twitter API, Discord API",
          description: "The RSOP Marketplace is a dynamic hub where players can use their ROYAL to bid on exclusive items, make direct purchases, or enter raffles that are updated regularly. Featuring a custom-built bidding system designed for a seamless and engaging user experience, the marketplace ensures fair competition and a rewarding way to spend your earned ROYAL",
           pageUrl:"https://www.royalsociety.io/royal"
        },
        {
          name: "Butchers Block (NFT Customisation)",
          image: "/images/rsopbutchersblock.gif",
          languages: "C# Web API, React, MSSQL, Ethereum Blockchain, Opensea API",
          description: "Inside Butcher’s Block, users can personalize their NFTs with a powerful, custom-built editing system inspired by Photoshop. This intuitive tool allows players to layer their own images, add text, and craft a truly one-of-a-kind NFT, unlocking endless possibilities for creativity and self-expression",
           pageUrl:"https://www.royalsociety.io/royal"
        },
        {
          name: "Members Portal",
          image: "/images/rsopmembersportal.png",
          languages: "React, Mongo, Ethereum Blockchain, Node.js",
          description: "The Royal Society of Players' Members Portal offers a comprehensive suite of tools and resources for its community. Members can connect their wallets to view and manage their NFTs, track poker qualifier points for tournament eligibility, and participate in voting to shape the society's direction. Additional features include customizable templates for creative expression, Zoom backgrounds, a member survey for feedback, and access to proposal submissions for treasury initiatives",
           pageUrl:"https://www.royalsociety.io/members"
        },
        {
          name: "Royal Match",
          image: "/images/rsoproyalmatch.png",
          languages: "React, Mongo, Node.js, Ethereum Blockchain",
          description: "Royal Match is a custom-built game where players use their NFTs to compete daily. By connecting their wallet on the RSOP website, players can play up to three games per day, with new rounds starting at 3 PM EST. Each turn allows up to three draws, and players can strategically hold or play cards to maximize their score. The game features limited cards (A’s, 2-6) with objectives like summing 6’s, forming a 4-card straight for 30 points, or earning mixed card points. It’s a strategic and engaging way to use your RSOP NFTs",
           pageUrl:"https://www.royalsociety.io/royalmatch"
        },
      ],
    },
  };

  const project = projectData[projectName];

  if (!project) {
    return (
      <section className="project-content-content">
        <div className="project-content-header">
          <h1>Project Not Found</h1>
          <p className="project-content-description">
            The project "{projectName}" does not exist or is not available.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section
      className="project-content-content"
      style={{
        backgroundImage: `url(${project.promoImage})`,
      }}
    >
      <div className="project-content-overlay">
        <div className="project-content-header" style={{
          backgroundImage: project.backgroundTheme ? `url(${project.backgroundTheme})` : undefined,
          backgroundColor: project.backgroundTheme ? undefined : project.theme
        }}>       
          <img src={project.logo} alt={`${project.title} promo`} className="project-content-image" />
          <p className="project-content-description">{project.description}</p>
          <div> <a href={project.website} target="_blank" rel="noopener noreferrer" className="btn-website" style={{color:project.textTheme}}>Visit Project Website</a></div>
        </div>
        <div className="project-content-details" style={{
          backgroundImage: project.backgroundTheme ? `url(${project.backgroundTheme})` : undefined,
          backgroundColor: project.backgroundTheme ? undefined : project.theme
        }}>
          <h2 style={{color:project.textTheme}}>Details</h2>
          <p>{project.details}</p>
        </div>
        {project.products && (
  <div className="project-content-products">
    <h2 style={{color:project.textTheme}}>Products</h2>
    <div className="product-grid">
      {project.products.map((product, index) => (
        product.standout === true ? (
          <div key={index} className="product-card standout">
            <h3>{product.name || "Unnamed Product"}</h3>
            {product.image && (
              <img
                src={product.image}
                alt={product.name}
                className="product-image"
              />
            )}
            <p>
              <strong style={{color:project.textTheme}}>Languages:</strong> {product.languages || "N/A"}
            </p>
            <p>{product.description || "No description available."}</p>
          </div>
        ) : (
          <div key={index} className="product-card">
            <h3>{product.name || "Unnamed Product"}</h3>
            {product.image && (
              <img
                src={product.image}
                alt={product.name}
                className="product-image"
              />
            )}
            <p>
              <strong style={{color:project.textTheme}}>Languages:</strong> {product.languages || "N/A"}
            </p>
            <p>{product.description || "No description available."}</p> 
            <div> <a href={product.pageUrl} target="_blank" rel="noopener noreferrer" className="btn-Visit" style={{color:project.textTheme}}>Visit Product</a></div>
          </div>
         
        )
      ))}
    </div>
  </div>
)}
      </div>
    </section>
  );
}

export default ProjectContent;
