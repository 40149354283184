import React from 'react';
import '../styles/Hero.css';
import { motion } from 'framer-motion';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        {/* Hero Title Animation */}
        <motion.h1 
          className="hero-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Luke Shepard
        </motion.h1>

        {/* Subtitle Animation */}
        <motion.h2 
          className="hero-subtitle"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Microsoft Certified Software Engineer
        </motion.h2>

        {/* Description Animation */}
        <motion.p
          className="hero-description"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          I specialize in building scalable, efficient, and innovative software solutions using cutting-edge technologies like C# and React.
        </motion.p>

        {/* Buttons Animation */}
        <motion.div 
          className="hero-buttons"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <a href="#projects" className="btn btn-primary">View My Projects</a>
          <a href="#contact" className="btn btn-secondary">Contact Me</a>
        </motion.div>
      </div>

      {/* Decorative Image */}
      <motion.div
        className="hero-image"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <img src="/images/profilepic.png" alt="Hero Illustration" />
      </motion.div>
    </section>
  );
}

export default Hero;
