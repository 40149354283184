import React from 'react';
import '../styles/Projects.css';
function Projects() {
  const companies = [
    {
      companyName: "Landmark Ventures",
      companyDescription: "A showcase of innovative solutions in blockchain, Web3, and NFT platforms, highlighting my expertise in creating scalable and impactful applications.",
      companyShowcase:"../images/landmarkprojectshowcase.png",
      projects: [
        {
          name: "House Of First NFT Platform",
          description:
            "Developed and maintained a pioneering NFT platform bridging art, blockchain, and culture. The platform enables artists to showcase and sell NFT collections while fostering community engagement.",
          technologies: [
            "React",
            "Node.js",
            "MongoDB",
            "GraphQL",
            "Twitter API",
            "C# Web API",
            "Ethereum Blockchain",
          ],
          demoLink: "https://houseoffirst.com/",
          image: "../images/houseoffirst.png",
          moreLink: "/ProjectDetail?project=houseOfFirst",
        },
        {
          name: "Royal Society NFT Platform",
          description:
            "Contributed to the development of the Royal Society platform, integrating NFTs with Ethereum blockchain for secure transactions and showcasing assets.",
          technologies: [
            "React",
            "C# Web API",
            "Ethereum Blockchain",
            "MetaMask",
            "MSSQL",
          ],
          demoLink: "https://www.royalsociety.io",
          codeLink: "",
          image: "../images/rsop.png",
          moreLink: "/ProjectDetail?project=rsop",
        },
        {
          name: "BetterBlocks NFT Monitor",
          description:
            "Built an NFT monitoring platform to provide businesses with tools for tracking their assets and automating posts on social media platforms.",
          technologies: [
            "React",
            "C# Web API",
            "MongoDB",
            "GraphQL",
            "Twitter API",
          ],
          demoLink: "https://app.betterblocks.io/blocks",
          codeLink: "",
          image: "../images/betterblocks.png",
          moreLink: "/ProjectDetail?project=betterBlocks",
        },
      ],
    },
    {
      companyName:"Arun Estates",
      companyDescription: "Streamlined real estate operations at Arun Estates through scalable Web2 platforms, enhancing user experience and internal workflows",
      companyShowcase:"/images/arunestatescompanypromo.png",
      projects: [
        {
          name: "Arun Estates Property Platform",
          description:
            "A comprehensive platform enabling users to search for properties to buy or rent, complemented by an internal system for estate agents to track and manage the progress of sales and rentals. This project highlights my expertise in developing user-centric solutions, database management, and seamless integration of frontend and backend systems to deliver efficient and scalable applications",
          technologies: [
            "C#",
            "MVC",
            ".NET Core",
            "MSSQL",
            "Blazor",
            "Google Maps API"
          ],
          demoLink: "https://www.cubittandwest.co.uk/property/for-sale/in-brighton-east-sussex",
          image: "/images/arunestateslogo.png",
        }
      ]
    }
  ];
  

  return (
    <section className="projects" id="projects"> 
    {companies.map((company, companyIndex) => (     
      <div className="project-background" style={{backgroundImage: `url(${company.companyShowcase})`}}>
        <div className="projects-content">
        <h2 className="projects-title">Projects at {company.companyName}</h2>
        <p className="projects-intro">
          {company.companyDescription}
        </p>   
          <div key={companyIndex} className="company-section">
            <div className="projects-grid">
              {company.projects.map((project, projectIndex) => (
                <div key={projectIndex} className="project-card">
                  <img
                    src={project.image}
                    alt={`${project.name} promo`}
                    className="project-image"
                  />
                  <div className="project-details">
                    <h3 className="project-name">{project.name}</h3>
                    <p className="project-description">{project.description}</p>
                    <div className="project-technologies">
                      {project.technologies.map((tech, techIndex) => (
                        <span key={techIndex} className="project-tech">
                          {tech}
                        </span>
                      ))}
                    </div>
                    <div className="project-links">
                      {project.demoLink && (
                        <a
                          href={project.demoLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary"
                        >
                          Visit Website
                        </a>
                      )}
                      {project.codeLink && (
                        <a
                          href={project.codeLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-secondary"
                        >
                          View Code
                        </a>
                      )}
                      {project.moreLink && (
                        <a
                          href={project.moreLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-secondary"
                        >
                          Project Details
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>      
      </div>
      </div>
    ))}
    </section>
  );
}
export default Projects;
