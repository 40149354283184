import React from 'react';
import '../styles/About.css';
import { motion } from 'framer-motion';

function About() {
  return (
    <section className="about" id="about">
    <div className="about-content">
      <motion.h2
        className="about-title"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        About Me
      </motion.h2>

      <motion.p
        className="about-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        I’m Luke Shepard, a Microsoft Certified Software Engineer with over 7 years of experience 
        crafting scalable, efficient, and innovative software solutions. From building enterprise 
        applications to exploring cutting-edge Web3 and blockchain technologies, I’m passionate about 
        solving complex problems and delivering exceptional results.
      </motion.p>

      <motion.p
        className="about-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        Whether I’m working on a full-stack web application or collaborating with a team to innovate 
        new tools, I approach every project with dedication, curiosity, and a drive to exceed expectations. 
        Let’s build something amazing together!
      </motion.p>

      <motion.div
        className="about-buttons"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <a href="#projects" className="btn btn-primary">View My Projects</a>
        <a href="#contact" className="btn btn-secondary">Get in Touch</a>
      </motion.div>
    </div>

    <motion.div
      className="about-image"
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1 }}
    >
      <img src="/images/aboutme.svg" alt="About Me Illustration" />
    </motion.div>
  </section>
  );
}

export default About;
